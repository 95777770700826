<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
  </div>
  <div class="box mb-4">
    <div class="grid grid-cols-3 gap-4 p-4">
      <span><span class="font-bold">Nombre Licitacion:</span> {{ licitacion.nombre }}</span>
      <span><span class="font-bold">Fecha Apertura: </span>{{ licitacion.fechaApertura }}</span>
      <span><span class="font-bold">Fecha Cierre: </span>{{ licitacion.fechaCierre }}</span>
      <span><span class="font-bold">Valor Presupuesto: </span>{{ $h.formatCurrency(licitacion.valorPresupuesto,0) }}</span>
      <span><span class="font-bold">Tipo Radicacion: </span>{{ licitacion.tipoRadicacion }}</span>
      <div style="display: flex">
        <a class="p-link mr-4" style="color: blue; text-decoration-line: underline;" @click="verPdf(licitacion.pathConsolidado)">Ver pdf</a>
        <a class="p-link" style="color: blue; text-decoration-line: underline;" @click="refresh">Actualizar Folios</a>
      </div>
    </div>
  </div>

  <DataTable :value="dataCargar" responsiveLayout="scroll" v-model:filters="filters1" :globalFilterFields="['Documento.nombre']">
    <template #header>
      <div class="flex justify-between">
        <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="clearFilter1()"/>
        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" placeholder="Buscar Documento" />
                        </span>
      </div>
    </template>
    <Column field="id" header="Nombre Documento" style="width: 40vh">
      <template #body="{data}">
        <span>{{data.nombre}}</span>
      </template>
    </Column>
    <Column header="Acciones" style="width: 20vh">
      <template #body="{data}">
        <div class="flex justify-start">
          <Button icon="pi pi-pencil"
                  class="mr-2"
                  style="height: 40px; width: 40px;"
                  @click="openModal(data)"
                  v-tooltip.top="'Editar Nombre'"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog v-model:visible="modalUpdateDoc" :style="{width: '450px'}" header="Editar Nombre" :modal="true" class="p-fluid">
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name"
                 v-model.trim="indice.nombre"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="hideModal"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveModal"
      />
    </template>
  </Dialog>

  <Dialog v-model:visible="modalPdf" :style="{width: '80%'}" contentStyle="overflow: hidden" header="Girar documento" :modal="true" class="p-fluid">
    <div id="embedPdf" style="display: block">
      <embed :src="rutaConsolidado" frameborder="0"
             id="pdf"
             width="100%"
             height="750rem"
      />
    </div>
    <div id="cargando" style="display: none; justify-content: center; align-items: center">
      <ProgressSpinner />
    </div>
    <template #footer>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div class="w-3/4" style="display: flex; align-items: center">
          <Button label="Girar"
                  icon="pi pi-replay"
                  class="mr-2 p-button-warning"
                  @click="girarPdf"
          />
          <div v-for="item of giros" :key="item.key" class="field-radiobutton ml-2">
            <RadioButton :id="item.key" name="category" :value="item.key" v-model="giro" />
            <label :for="item.key">{{item.nombre}}</label>
          </div>
          <InputNumber v-model="pageNumber" placeholder="No. Pag." style="width: 20%; margin-left: 4px"/>
        </div>
      </div>
    </template>
  </Dialog>

  <Toast/>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import LicitacionesService from '../../services/LicitacionesService'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import dayjs from 'dayjs'

export default {
  name: 'Modificar Indices',
  setup () {
    const route = useRoute()
    const dataCargar = ref([])
    const toast = useToast()
    const _LicitacionesService = ref(new LicitacionesService())
    const documento = ref()
    const licitacion = ref({
      nombre: '',
      fechaApertura: '',
      fechaCierre: '',
      valorPresupuesto: null,
      tipoRadicacion: null
    })
    const users = ref([])
    const expandedRowGroups = ref(null)
    const activeIndex = ref([])
    const filters1 = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const modalUpdateDoc = ref(false)
    const modalPdf = ref(false)
    const indice = ref({
      nombre: '',
      id: null
    })
    const giro = ref(null)
    const giros = ref([
      { nombre: '0°', key: '0' },
      { nombre: '90°', key: '90' },
      { nombre: '180°', key: '180' },
      { nombre: '270°', key: '270' }
    ])
    const pageNumber = ref(null)
    const rutaConsolidado = ref(null)

    onMounted(() => {
      getOnLoad()
    })

    // Methods
    const getOnLoad = () => {
      _LicitacionesService.value.getLicitacion(route.params.id).then(({ data }) => {
        licitacion.value.nombre = data.nombre
        licitacion.value.fechaApertura = dayjs(data.fechaApertura).format('DD/MM/YYYY')
        licitacion.value.fechaCierre = dayjs(data.fechaCierre).format('DD/MM/YYYY')
        licitacion.value.valorPresupuesto = data.valorPresupuesto
        licitacion.value.tipoRadicacion = data.tipoRadicacion ? 'Virtual' : 'Fisico'
        licitacion.value.pathConsolidado = data.pathConsolidado
      })
      _LicitacionesService.value.getIndices(route.params.id).then(({ data }) => {
        dataCargar.value = data
      })
    }

    const openModal = ({ nombre, id }) => {
      indice.value.nombre = nombre
      indice.value.id = id
      modalUpdateDoc.value = !modalUpdateDoc.value
    }

    const openModalPdf = () => {
      modalPdf.value = !modalPdf.value
    }

    const clearFilter1 = () => {
      filters1.value.global.value = null
    }

    const saveModal = () => {
      _LicitacionesService.value.updateIndices(indice.value.id, { licitacionId: route.params.id, nombre: indice.value.nombre }).then(({ data }) => {
        toast.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Se actualizo correctamente',
          life: 3000
        })
        getOnLoad()
        modalUpdateDoc.value = !modalUpdateDoc.value
      }).catch(({ response }) => {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo actualizar',
          life: 3000
        })
      })
    }

    const verPdf = (ruta) => {
      if (ruta) {
        _LicitacionesService.value.getPdf(ruta).then((url) => {
          rutaConsolidado.value = url
          openModalPdf()
        })
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
      }
    }

    const girarPdf = () => {
      if (pageNumber.value && giro.value) {
        if (licitacion.value.pathConsolidado) {
          document.getElementById('cargando').style.display = 'flex'
          const embed = document.getElementById('embedPdf')
          const element = document.getElementById('pdf')
          element.parentNode.removeChild(element)
          _LicitacionesService.value.girarPdf({
            pathConsolidado: licitacion.value.pathConsolidado,
            page: pageNumber.value,
            rotate: parseInt(giro.value)
          }).then((url) => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se giro correctamente', life: 3000 })
            getOnLoad()
            console.log(element)
            document.getElementById('cargando').style.display = 'none'
            embed.append(element)
            // document.getElementById('pdf').contentDocument = location.reload()
          })
        } else {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
        }
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El numero de pagina o cantidad de giro es obligatorio', life: 3000 })
      }
    }

    const refresh = () => {
      _LicitacionesService.value.refresh(route.params.id).then(({ data }) => {
        toast.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Se refresco correctamente',
          life: 3000
        })
        getOnLoad()
      }).catch(({ response }) => {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo refrescar',
          life: 3000
        })
      })
    }

    return {
      clearFilter1,
      dataCargar,
      openModal,
      documento,
      licitacion,
      users,
      expandedRowGroups,
      activeIndex,
      filters1,
      modalUpdateDoc,
      indice,
      hideModal () {
        modalUpdateDoc.value = false
      },
      saveModal,
      verPdf,
      modalPdf,
      openModalPdf,
      giro,
      giros,
      girarPdf,
      pageNumber,
      rutaConsolidado,
      refresh
    }
  }
}
</script>

<style scoped>

</style>
